<template>
  <div>
    <ConfirmDialog
      message="ต้องการดำเนินการต่อหรือไม่ ?"
      ref="confirmDialogue"
    />
    <v-container>
      <v-row class="mt-2">
        <v-col cols="12" class="py-0">
          <div class="d-flex justify-space-between">
            <h2 class="text-secondary header">จัดการ Admin</h2>
            <v-btn
              @click="$router.push('/management-admin/add')"
              color="primary"
              dark
              class="font-weight-bold"
              >เพิ่มแอดมิน</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div class="mt-6">
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="5"
        :footer-props="footer"
        no-data-text="ไม่พบข้อมูล"
        no-results-text="ไม่พบข้อมูล"
        class="elevation-1"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn
            v-if="item._id !== authId"
            @click="$router.push(`/management-admin/edit/${item.username}`)"
            icon
            ><v-icon>mdi-pencil</v-icon></v-btn
          >
          <v-btn
            v-if="item._id !== authId"
            @click="deleteAuthMember(item._id)"
            color="red"
            icon
            ><v-icon>mdi-delete</v-icon></v-btn
          >
        </template>
        <template v-slot:item.branch="{ item }">
          {{ item.branch | formatBranch }}
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ConfirmDialog from "@/components/Confirm.vue";
import axios from "../../utils/axios.js";
export default {
  mounted() {
    if (this.info && this.info.profile.id !== process.env.VUE_APP_AUTH_ID) {
      this.$router.push("/");
    }
    this.getAuthMember();
  },
  components: {
    ConfirmDialog,
  },
  computed: {
    footer() {
      return {
        "items-per-page-text": "รายการต่อหน้า",
        "items-per-page-all-text": "ทั้งหมด",
      };
    },
    authId() {
      return process.env.VUE_APP_AUTH_ID;
    },
    ...mapGetters({
      info: "User/info",
    }),
  },
  data() {
    return {
      headers: [
        {
          text: "ชื่อผู้ใช้",
          align: "start",
          sortable: false,
          value: "username",
        },
        { text: "ชื่อจริง", value: "firstname" },
        { text: "นามสกุล", value: "lastname" },
        { text: "สาขา", value: "branch" },
        { text: "", value: "actions" },
      ],
      items: [],
    };
  },
  methods: {
    async getAuthMember() {
      try {
        const {
          data: { data },
        } = await axios.get("/auth");
        this.items = data;
      } catch (error) {
        console.error(error);
      }
    },
    async deleteAuthMember(event) {
      try {
        const confirm = await this.$refs.confirmDialogue.show();
        if (confirm) {
          await axios.delete(`/auth/${event}`);
          this.getAuthMember();
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
